import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const EnquireFormContent = styled.div`
  ${tw`relative flex flex-col w-full font-display bg-grey__light text-left px-4 mb-4`}
`
export const EnquireFormHeader = styled.h6`
  margin-top: 1rem !important;
  margin-bottom: 0px !important;
  ${tw`text-lg text-black w-full font-display px-4`}
`

export const EnquireFormHeroWrapper = styled.div<{
  template?: LAYOUT_TEMPLATE
}>`
  ${tw`relative flex flex-col w-full font-display bg-orange z-20 xl:pt-4`}
  &::before {
    ${tw`absolute w-full block bg-orange left-0`}
    content: " ";
    top: -1.75rem;
    z-index: 0;
    height: 30px;
    transform: skewY(-2deg);
    transform-origin: 100%;

    @media (min-width: 1024px) {
      top: 0rem;
    }
  }
  &::after {
    ${tw`absolute w-full block bg-orange left-0 bottom-0`}
    content: " ";
    z-index: 0;
    height: 30px;
    transform: skewY(-2deg);
    transform-origin: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 420px;
  }

  // You Fitness Template
  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-blue__deep xl:pt-4 xl:pb-4 px-5`}
      max-width: 340px;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      &::before,
      &::after {
        display: none;
      }

      ${EnquireFormHeader} {
        ${tw`text-center`}
      }

      form {
        ${tw`m-0 w-full`}

        > div:first-child {
          ${tw`mt-0`}
        }

        > div > span {
          ${tw`text-red italic absolute`}
          font-size: 13px;
          top: 100%;
        }

        > div > input,
        .react-datepicker__input-container input,
        .you-fitness-fileinput,
        .you-fitness__control {
          ${tw`text-sm`}
          height: 48px;
        }

        .you-fitness-error,
        .error.you-fitness__control {
          ${tw`border-2 border-red`}
        }

        label {
          ${tw`text-white text-sm mb-1`}
        }

        .you-fitness__placeholder,
        input {
          ${tw`text-sm`}
        }

        .you-fitness-fileinput label {
          ${tw`text-grey`}
        }

        > div:last-child {
          ${tw`text-center flex-row items-center justify-center pt-3`}
        }
      }

      button {
        padding: 0.57rem 2rem;
        ${tw`bg-black not-italic capitalize text-sm`}
      }
    `}
`

export const EnquireFormWrapper = styled.div<{
  template?: LAYOUT_TEMPLATE
}>`
  ${tw`w-full flex flex-col z-20`}

  // You Fitness Template
  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${EnquireFormContent} {
        ${tw`bg-transparent`}
      }

      ${EnquireFormHeader} {
        ${tw`text-white`}
      }
    `}
`
