import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const FormWrapper = styled.div<{
  backgroundColor?: 'orange' | 'blue' | 'black-deep'
}>`
  ${tw`relative mx-auto flex flex-col w-full font-display`}
  max-width: 1024px;

  ${({ backgroundColor }) => {
    switch (backgroundColor) {
      case 'orange':
        return tw`bg-orange`
      case 'blue':
        return tw`bg-blue`
      case 'black-deep':
        return tw`bg-black__deep`
      default:
        return tw`bg-grey__light`
    }
  }}

  // You Fitness template
  ${({ backgroundColor }) => backgroundColor === 'black-deep' && css`
    ${FormLabel},
    h2 {
      ${tw`text-white`}
    }
  `}
`

export const Form = styled.form`
  ${tw`m-4`}
  z-index: 1;
`

export const PreFormText = styled.p`
  ${tw`px-4 pt-4 mb-0 font-body`}
`

export const FormTitle = styled.h1`
  ${tw`px-4 pt-8 mb-0 font-body`}
`

export const FormGroup = styled.div<{ col?: number; paddingX?: number }>`
  ${tw`flex flex-col my-4 w-full relative`}

  ${({ col }) => {
    if (col === 1) return tw`w-full`
    else if (col === 2) return tw`lg:w-2/5`
    else if (col === 3) return tw`lg:w-1/3`
    else return ''
  }}

  ${({ paddingX }) => {
    if (paddingX === 1) return tw`lg:px-1`
    else if (paddingX === 2) return tw`lg:px-2`
    else if (paddingX === 3) return tw`lg:px-3`
    else if (paddingX === 4) return tw`lg:px-4`
    else if (paddingX === 5) return tw`lg:px-5`
    else if (paddingX === 6) return tw`lg:px-6`
    else return ''
  }}
`

export const FormLabel = styled.label`
  ${tw`text-blue__dark font-medium`}
`

export const ErrorMessage = styled.span`
  ${tw`text-red__pinterest`}
`

export const CloseWrapper = styled.span`
  ${tw`absolute top-0 right-0 p-4 cursor-pointer`}
`
